import { useEffect } from 'react'
import { PseudoTemplate } from '../../../clients/pseudoTemplates'
import { useApi } from '../../../hooks/useApi'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import Button from '../../../shared/buttons/Button'
import LoadingScreen from '../../../shared/LoadingScreen'
import StickyFooter from '../../../shared/StickyFooter'
import Template from './Template'
import TemplateCard from './TemplateCard'
import ScrollToTop from '../../../shared/ScrollToTop'

function orderPseudoTemplatesByCustomOrder(
  pseudoTemplates: PseudoTemplate[],
  orderedTemplateIds: string[]
) {
  return pseudoTemplates.sort((a, b) => {
    return orderedTemplateIds.indexOf(a.id) - orderedTemplateIds.indexOf(b.id)
  })
}

const getFallbackPhotoUrl = (variantId: number, backsideId: number) => {
  return `/photo-products/back-sides/${variantId}/fallback/${backsideId}.png`
}

function PseudoTemplateListItem({
  selectPseudoTemplate,
  photo,
  pseudoTemplate,
  index,
}: {
  pseudoTemplate: PseudoTemplate
  selectPseudoTemplate: (pseudoTemplate: PseudoTemplate, position: number) => void
  photo: string
  index: number
}) {
  const { name, template, backsideUrl, templateWidth, templateHeight } = pseudoTemplate

  return (
    <li>
      <button onClick={() => selectPseudoTemplate(pseudoTemplate, index)}>
        <TemplateCard name={name}>
          <Template
            templateWidth={templateWidth}
            templateHeight={templateHeight}
            designTemplate={template}
            photos={[photo, backsideUrl]}
          />
        </TemplateCard>
      </button>
    </li>
  )
}

export default function SelectPseudoTemplatePage({
  selectPseudoTemplate,
  curatedPhoto,
  arePhotosLoaded,
  pseudoTemplates,
  previous,
  variantId,
  cartHeader,
  orderedTemplateIds,
}: {
  selectPseudoTemplate: (pseudoTemplate: PseudoTemplate, position: number) => void
  curatedPhoto: string | null
  arePhotosLoaded: boolean
  pseudoTemplates: PseudoTemplate[]
  previous: () => void
  variantId: number
  cartHeader: React.ReactNode
  orderedTemplateIds: string[]
}) {
  const { appVersionSupportsWebViewBackAndCloseButtons } = useFamilyAlbumUserAgent()
  const { recordDidViewSelectTemplatePage } = useApi().recordUserAction.photoProducts

  useEffect(() => {
    recordDidViewSelectTemplatePage({
      isShowingUserPhotos: !!curatedPhoto,
    })
  }, [])

  if (!arePhotosLoaded) {
    return <LoadingScreen />
  }

  const orderedPseudoTemplates = orderPseudoTemplatesByCustomOrder(
    pseudoTemplates,
    orderedTemplateIds
  )

  return (
    <>
      <ScrollToTop />
      <div className="flex flex-col h-screen">
        <div className="mx-auto max-w-screen-lg">
          {cartHeader}
          <ul className="p-4 grid grid-cols-2 gap-4 gap-y-6 grow">
            {orderedPseudoTemplates.map((pseudoTemplate, index) => {
              return (
                <PseudoTemplateListItem
                  key={pseudoTemplate.id}
                  pseudoTemplate={pseudoTemplate}
                  selectPseudoTemplate={selectPseudoTemplate}
                  index={index}
                  photo={curatedPhoto || getFallbackPhotoUrl(variantId, pseudoTemplate.backsideId)}
                />
              )
            })}
          </ul>
        </div>
        {appVersionSupportsWebViewBackAndCloseButtons || (
          <StickyFooter className="z-10">
            <Button onClick={previous} className="w-full" colorVariant="primary">
              Back
            </Button>
          </StickyFooter>
        )}
      </div>
    </>
  )
}
