import Button from '../../../shared/buttons/Button'

export default function LargeBannerSection({
  staticFileFolder,
  title,
  description,
  callToAction,
  onCallToActionClick,
}: {
  staticFileFolder: string
  title: string
  description: string
  callToAction: string
  onCallToActionClick: () => void
}) {
  return (
    <section className="flex flex-col items-center justify-center py-8 relative">
      <img src={`${staticFileFolder}/large-banner-bg.png`} alt={title} />
      <div className="flex flex-col items-center justify-center absolute">
        <div className="bg-white rounded-lg p-6 space-y-4 text-center">
          <div>
            <h3 className="font-bold text-gray-12 text-[26px]">{title}</h3>
            <p className="text-gray-12">{description}</p>
          </div>
          <Button
            className="rounded-[999px] px-5 py-[.15rem] font-bold"
            colorVariant="primary"
            onClick={onCallToActionClick}
          >
            {callToAction}
          </Button>
        </div>
      </div>
    </section>
  )
}
