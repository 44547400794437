export enum PhotoProductCategory {
  MUGS = 'mugs',
  SOCKS = 'socks',
  WRAPPING_PAPER = 'wrapping-paper',
  ORNAMENTS = 'ornaments',
  PUZZLES = 'puzzles',
}

export type PhotoProductCategoryId = keyof typeof PhotoProductCategory

export type ListProduct = {
  name: string
  productId: number
  rebate: string
  variantId?: number
  provider: 'PRINTIFY' | 'PRINTFUL'
}

export type ProductCatalog = Record<
  string,
  {
    products: ListProduct[]
    title: string
    bgColor?: string
  }
>

export const productCatalogUs: ProductCatalog = {
  MUGS: {
    products: [
      {
        name: 'Colorful Mug 11oz',
        productId: 1151,
        rebate: '16%',
        provider: 'PRINTIFY',
      },
      {
        name: 'Mug 11oz',
        productId: 68,
        rebate: '16%',
        provider: 'PRINTIFY',
      },
      {
        name: 'Mug 15oz',
        productId: 425,
        rebate: '18%',
        provider: 'PRINTIFY',
      },
      {
        name: 'Enamel Camp Cup',
        productId: 1115,
        rebate: '18%',
        provider: 'PRINTIFY',
      },
      {
        name: 'Jumbo Mug 20oz',
        productId: 1126,
        rebate: '18%',
        provider: 'PRINTIFY',
      },
    ],
    title: 'Mugs',
    bgColor: '#fffbe9',
  },
  SOCKS: {
    products: [
      {
        name: 'One Size Fits All Socks',
        productId: 574,
        rebate: '19%',
        provider: 'PRINTIFY',
      },
    ],
    title: 'Socks',
    bgColor: '#f4f9fd',
  },
  PUZZLES: {
    products: [
      {
        name: 'Puzzle',
        productId: 616,
        rebate: '20%',
        provider: 'PRINTIFY',
      },
    ],
    title: 'Puzzles',
    bgColor: '#e8f6e9',
  },
}

export const productSelectionByMarket: Record<string, ProductCatalog> = {
  US: productCatalogUs,
  CA: productCatalogUs,
  GB: productCatalogUs,
}

export const getProductCatalogForMarket = (market: string | null) => {
  if (!market) {
    return productCatalogUs
  }

  return productSelectionByMarket[market] || productCatalogUs
}
