import cx from 'classnames'
import RebateBadge from './RebateBadge'
import { buttonVariants } from './buttons/config'

export default function PhotoGiftProductCard({
  src,
  text,
  rebate,
  bgColor,
}: {
  src: string
  text: string
  rebate?: string
  bgColor?: string
}) {
  return (
    <div
      className="w-full sm:w-auto flex flex-col bg-gray-2 items-center rounded-lg shadow-photo-product-card"
      style={bgColor ? { backgroundColor: bgColor } : undefined}
    >
      <div className="relative h-[120px] w-full">
        <img src={src} alt={text} className="h-full w-full object-contain" />
        {rebate && <RebateBadge rebate={rebate} className="absolute top-2 right-0" />}
      </div>
      <div className="bg-white pb-2 rounded-b-lg flex flex-col h-[80px] text-center text-black font-normal w-full pt-2">
        <p>{text}</p>
        <div
          role="button"
          className={cx(
            buttonVariants.secondary,
            'rounded-full text-15 font-bold w-20 py-0.5 mx-auto mt-auto'
          )}
        >
          Create
        </div>
      </div>
    </div>
  )
}
