import { useNavigate } from 'react-router-dom'
import ErrorPage from '../ErrorPage'

const env = import.meta.env.VITE_ENVIRONMENT || 'local'

export default function PhotoProductsFallbackUi({
  error,
  resetError,
}: {
  error: Error | null
  resetError: () => void
}) {
  const navigate = useNavigate()
  const onClick = () => {
    resetError()
    navigate('/photo-products/categories')
  }

  return (
    <ErrorPage
      error="Something went wrong"
      message="We are sorry for the inconvenience. Please try again later or click below to start over."
      buttonText="Start over"
      onButtonClick={() => {
        onClick()
      }}
    />
  )
}
