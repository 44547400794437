import semver from 'semver'
import { isDeviceIOS, isDeviceAndroid } from '../utils'

export default function useFamilyAlbumUserAgent() {
  const agentInfoParser = () => {
    const userAgent = navigator.userAgent
    const matches = userAgent.match(
      /\b(Mitene|MayChannel|Mitene-Android|MiteneWebView)\/([\d\.]+)\b/
    )
    const matched = matches && matches.length == 3
    const appName = matched ? matches[1] : ''
    const appVersion = matched ? matches[2] : ''
    const isAndroid = isDeviceAndroid()
    const isIOS = isDeviceIOS()
    return {
      matched,
      appName,
      appVersion,
      isIOS,
      isAndroid,
      isDesktop: !isIOS && !isAndroid,
    }
  }
  const agentInfo = agentInfoParser()

  const appVersionSatisfies = (range: string) => {
    return agentInfo.matched && semver.satisfies(agentInfo.appVersion, range)
  }

  return {
    appVersionSatisfies,
    appVersionSupportsCloseWebView: appVersionSatisfies('>=22.3.0'),
    appVersionSupportsWebViewBackAndCloseButtons: appVersionSatisfies('>=22.12.0'),
    appVersionSupportsUpload:
      agentInfo.isIOS || appVersionSatisfies('>=22.4.0') || agentInfo.isDesktop,
  }
}
