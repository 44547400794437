import { Fragment } from 'react'
import cx from 'classnames'

export default function FooterButtons({ buttons }: { buttons: (React.ReactNode | undefined)[] }) {
  const filteredButtons = buttons.filter(Boolean)
  const keyedButtons = filteredButtons.map((button, index) => {
    return <Fragment key={index}>{button}</Fragment>
  })
  return <div className={cx('grid gap-2', `grid-cols-${keyedButtons.length}`)}>{keyedButtons}</div>
}
