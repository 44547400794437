import CuratedPhotosHeaderContent from './CuratedPhotosHeaderContent'
import CopyShareYourLove from '../../../assets/photo-gifts/header_copy_share_your_love.svg?react'
import CopyTurns1Month from '../../../assets/photo-gifts/header_copy_turns_1_month.svg?react'
import CopyTurns3Months from '../../../assets/photo-gifts/header_copy_turns_3_months.svg?react'
import CopyTurns6Months from '../../../assets/photo-gifts/header_copy_turns_6_months.svg?react'
import CopyTurns9Months from '../../../assets/photo-gifts/header_copy_turns_9_months.svg?react'
import Illustration1Month from '../../../assets/photo-gifts/header_milestone_1_illustration.png'
import Illustration3Months from '../../../assets/photo-gifts/header_milestone_3_illustration.png'
import Illustration6Months from '../../../assets/photo-gifts/header_milestone_6_illustration.png'
import Illustration9Months from '../../../assets/photo-gifts/header_milestone_9_illustration.png'
import { CuratedPhotoForPhotoProducts } from '../../../clients/fagl-server/types'

export enum MilestoneHeaderType {
  OneMonth = 'milestone-1-month',
  ThreeMonths = 'milestone-3-month',
  SixMonths = 'milestone-6-month',
  NineMonths = 'milestone-9-month',
}

const FIGMA_WIDTH = 375
const FIGMA_HEIGHT = 240
const FRAME_OFFSET_BASE = {
  frame1: { left: 0.2 / FIGMA_WIDTH, top: 0.0 },
  frame2: { left: 12.52 / FIGMA_WIDTH, top: 0.0 / FIGMA_HEIGHT },
  frame3: { left: 11.83 / FIGMA_WIDTH, top: 0.065 / FIGMA_HEIGHT },
}
const FRAME_OFFSET_MILESTONES_1_3_6 = FRAME_OFFSET_BASE
const FRAME_OFFSET_MILESTONE_9 = {
  frame1: { ...FRAME_OFFSET_BASE.frame1, top: FRAME_OFFSET_BASE.frame1.top - 7 / FIGMA_HEIGHT },
  frame2: { ...FRAME_OFFSET_BASE.frame2, top: FRAME_OFFSET_BASE.frame2.top - 7 / FIGMA_HEIGHT },
  frame3: { ...FRAME_OFFSET_BASE.frame3, top: FRAME_OFFSET_BASE.frame3.top - 7 / FIGMA_HEIGHT },
}
const MILESTONE_HEADERS = {
  [MilestoneHeaderType.OneMonth]: {
    backgroundColor: '#FFF7ED',
    copy: CopyTurns1Month,
    illustration: Illustration1Month,
    staticFileFolder: '/photo-products/landing/milestone_1',
    frameOffsets: FRAME_OFFSET_MILESTONES_1_3_6,
  },
  [MilestoneHeaderType.ThreeMonths]: {
    backgroundColor: '#E8F7FD',
    copy: CopyTurns3Months,
    illustration: Illustration3Months,
    staticFileFolder: '/photo-products/landing/milestone_3',
    frameOffsets: FRAME_OFFSET_MILESTONES_1_3_6,
  },
  [MilestoneHeaderType.SixMonths]: {
    backgroundColor: '#FEFBE9',
    copy: CopyTurns6Months,
    illustration: Illustration6Months,
    staticFileFolder: '/photo-products/landing/milestone_6',
    frameOffsets: FRAME_OFFSET_MILESTONES_1_3_6,
  },
  [MilestoneHeaderType.NineMonths]: {
    backgroundColor: '#E9F6E9',
    copy: CopyTurns9Months,
    illustration: Illustration9Months,
    staticFileFolder: '/photo-products/landing/milestone_9',
    frameOffsets: FRAME_OFFSET_MILESTONE_9,
  },
}

export default function MilestoneHeader({
  headerType,
  name,
  curatedPhotos,
  isUsingFallback,
}: {
  headerType: MilestoneHeaderType
  name: string
  curatedPhotos: CuratedPhotoForPhotoProducts[]
  isUsingFallback: boolean
}) {
  const headerMeta = MILESTONE_HEADERS[headerType as MilestoneHeaderType]

  const copyArea = (
    <>
      <div className="relative">
        <headerMeta.copy className="w-full" />
        <div className="w-full absolute top-[-8px] text-center text-[#107AB5] text-[26px] font-bold font-['SF Pro']">
          {name}
        </div>
      </div>
      <CopyShareYourLove className="w-full" />
    </>
  )

  // NOTE: When user photos can be included in the landing page, they should be.
  //       If they are unavailable we should fall back to the illustrated version.
  const illustrationPattern = isUsingFallback || curatedPhotos.length < 3

  return illustrationPattern ? (
    <>
      <header>
        <img className="w-full h-full object-cover" src={headerMeta.illustration} />
      </header>
      <div className="grid gap-2 my-6 pb-4">{copyArea}</div>
    </>
  ) : (
    <>
      <div className="grid gap-2 pt-8" style={{ backgroundColor: headerMeta.backgroundColor }}>
        {copyArea}
      </div>
      <header className="pb-4 relative">
        <CuratedPhotosHeaderContent
          staticFileFolder={headerMeta.staticFileFolder}
          curatedPhotos={curatedPhotos}
          isUsingFallback={isUsingFallback}
          frameOffsets={headerMeta.frameOffsets}
        />
      </header>
    </>
  )
}
