import { useCallback } from 'react'
import useFamilyAlbumUserAgent from './useFamilyAlbumUserAgent'

export default function useAppInterface() {
  const { appVersionSupportsWebViewBackAndCloseButtons } = useFamilyAlbumUserAgent()
  const webkit = window.webkit

  const preventWebViewBack = useCallback(() => {
    if (!appVersionSupportsWebViewBackAndCloseButtons) {
      return
    }

    if (webkit) {
      webkit.messageHandlers?.webViewPreventBackInterface?.postMessage('true')
    } else {
      const androidPreventBackInterface = window.AndroidPreventBackInterface
      androidPreventBackInterface?.enable()
    }
  }, [])

  const showWebViewCloseButton = useCallback(() => {
    if (!appVersionSupportsWebViewBackAndCloseButtons) {
      return
    }

    if (webkit) {
      webkit.messageHandlers?.webViewProvideCloseButtonInterface?.postMessage(
        JSON.stringify({ enabled: true })
      )
    } else {
      const androidProvideCloseButtonInterface = window.AndroidProvideCloseButtonInterface
      androidProvideCloseButtonInterface?.enable(null, null)
    }
  }, [])

  const showWebViewCloseButtonWithDialog = useCallback(() => {
    if (!appVersionSupportsWebViewBackAndCloseButtons) {
      return
    }

    const data = {
      enabled: true,
      dialogTitle: 'Are you sure?',
      dialogMessage: 'Your changes will be lost.',
    }
    if (webkit) {
      webkit.messageHandlers?.webViewProvideCloseButtonInterface?.postMessage(JSON.stringify(data))
    } else {
      const androidProvideCloseButtonInterface = window.AndroidProvideCloseButtonInterface
      androidProvideCloseButtonInterface?.enable(data.dialogTitle, data.dialogMessage)
    }
  }, [])

  const hideWebViewCloseButton = useCallback(() => {
    if (!appVersionSupportsWebViewBackAndCloseButtons) {
      return
    }

    if (webkit) {
      webkit.messageHandlers?.webViewProvideCloseButtonInterface?.postMessage(
        JSON.stringify({ enabled: false })
      )
    } else {
      const androidProvideCloseButtonInterface = window.AndroidProvideCloseButtonInterface
      androidProvideCloseButtonInterface?.disable()
    }
  }, [])

  return {
    showWebViewCloseButton,
    showWebViewCloseButtonWithDialog,
    hideWebViewCloseButton,
    preventWebViewBack,
  }
}
