import { useCallback } from 'react'
import { Carousel, CarouselItem } from '../../../shared/Carousel'
import { productCatalogUs, PhotoProductCategoryId } from '../../../clients/productCatalog'
import RebateBadge from '../../../shared/RebateBadge'

export default function CategoryProductsCarousel({
  category,
  staticFileFolder,
  numberOfPhotos,
  title,
  selectCategory,
}: {
  category: PhotoProductCategoryId
  staticFileFolder: string
  numberOfPhotos: number
  title: string
  selectCategory: (category: PhotoProductCategoryId, clickedElement: string) => void
}) {
  const onCategoryClick = useCallback(
    (clickedElement: string) => {
      selectCategory(category, clickedElement)
    },
    [selectCategory, category]
  )

  const MUG_11OZ = productCatalogUs.MUGS.products.find((p) => {
    return p.name == 'Mug 11oz'
  })
  const ENAMEL_CAMP_MUG = productCatalogUs.MUGS.products.find((p) => {
    return p.name == 'Enamel Camp Cup'
  })
  const SOCKS = productCatalogUs.SOCKS.products[0]
  const PUZZLES = productCatalogUs.PUZZLES.products[0]
  const PRODUCT_BY_CATEGORY_AND_INDEX = {
    MUGS: [MUG_11OZ, MUG_11OZ, MUG_11OZ, ENAMEL_CAMP_MUG, MUG_11OZ, MUG_11OZ],
    SOCKS: [SOCKS, SOCKS, SOCKS, SOCKS, SOCKS, SOCKS],
    PUZZLES: [PUZZLES, PUZZLES, PUZZLES, PUZZLES, PUZZLES, PUZZLES],
    WRAPPING_PAPER: [],
    ORNAMENTS: [],
  }

  const items = Array.from({ length: numberOfPhotos }, (_, index) => {
    return {
      index,
      photoUrl: `${staticFileFolder}/categories/${category}/carousel/${index + 1}.png`,
      rebate: (PRODUCT_BY_CATEGORY_AND_INDEX[category] || {})[index]?.rebate,
    }
  })
  return (
    <section className="space-y-4">
      <h3 className="font-bold text-lg px-4">{title}</h3>
      <Carousel
        scrollContainerClassName="pr-4"
        items={items}
        renderItem={({ item, isSnapPoint, index }) => (
          <CarouselItem key={item.index} isSnapPoint={isSnapPoint} className="pl-4">
            <button
              onClick={() => onCategoryClick(`carousel-${category}-tile-${index}`)}
              className="rounded-lg overflow-hidden bg-gray-2 relative"
            >
              <img src={item.photoUrl} className="rounded-lg" width="132" height="135" alt="" />
              {item.rebate && (
                <RebateBadge rebate={item.rebate} className="absolute top-2 right-2" />
              )}
            </button>
          </CarouselItem>
        )}
      />
      <div className="text-right px-4">
        <button
          onClick={() => onCategoryClick(`carousel-${category}-see-more`)}
          className="font-bold text-mi-primary"
        >
          See more design options
        </button>
      </div>
    </section>
  )
}
