import { useCallback, useRef, useState } from 'react'
import { CuratedPhotoForPhotoProducts } from '../../../clients/fagl-server/types'

type FrameSizeRelativeNumbers = {
  left: number
  top: number
  width: number
  height: number
  rotate: number
}

const WIDTH = 390
const HEIGHT = 250
const FRAME1 = {
  left: (45.5 / WIDTH) * 100,
  top: (95 / HEIGHT) * 100,
  width: (141 / WIDTH) * 100,
  height: (100 / HEIGHT) * 100,
  rotate: 351,
}

const FRAME2 = {
  left: (217 / WIDTH) * 100,
  top: (42 / HEIGHT) * 100,
  width: (79.54 / WIDTH) * 100,
  height: (107.64 / HEIGHT) * 100,
  rotate: -12.89,
}

const FRAME3 = {
  left: (260.16 / WIDTH) * 100,
  top: (143.78 / HEIGHT) * 100,
  width: (67.25 / WIDTH) * 100,
  height: (85.59 / HEIGHT) * 100,
  rotate: 19.26,
}

export default function CuratedPhotosHeaderContent({
  staticFileFolder,
  curatedPhotos,
  isUsingFallback,
  frameOffsets,
}: {
  staticFileFolder: string
  curatedPhotos: CuratedPhotoForPhotoProducts[]
  isUsingFallback: boolean
  frameOffsets?: {
    frame1: { left: number; top: number }
    frame2: { left: number; top: number }
    frame3: { left: number; top: number }
  }
}) {
  const [boundingBox, setBoundingBox] = useState<DOMRect | null>(null)

  const imageRef = useRef<HTMLImageElement>(null)
  const getFrameSizeAbsolute = useCallback(
    (
      frameSizeRelativeNumbers: FrameSizeRelativeNumbers,
      frameOffset?: { left: number; top: number }
    ) => {
      if (!boundingBox)
        return {
          width: 0,
          height: 0,
          top: 0,
          left: 0,
        }
      return {
        width: (boundingBox.width * frameSizeRelativeNumbers.width) / 100,
        height: (boundingBox.height * frameSizeRelativeNumbers.height) / 100,
        top:
          (boundingBox.height * (frameSizeRelativeNumbers.top + (frameOffset?.top || 0) * 100)) /
          100,
        left:
          (boundingBox.width * (frameSizeRelativeNumbers.left + (frameOffset?.left || 0) * 100)) /
          100,
      }
    },
    [boundingBox]
  )

  const urlsToUse = isUsingFallback ? [] : curatedPhotos.map((photo) => photo.croppedUrl)

  if (urlsToUse.length < 3) {
    return (
      <img src={`${staticFileFolder}/header-static.jpg`} className="w-full h-full object-cover" />
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    swipeToSlide: true,
    arrows: false,
    fade: true,
  }

  return (
    <>
      <div
        className="absolute overflow-hidden bg-white z-0"
        style={{
          ...getFrameSizeAbsolute(FRAME1, frameOffsets?.frame1),
          transform: `rotate(${FRAME1.rotate}deg)`,
        }}
      >
        <img src={urlsToUse[0]} className="w-full h-full object-cover" />
      </div>
      <div
        className="absolute overflow-hidden bg-white z-0"
        style={{
          ...getFrameSizeAbsolute(FRAME2, frameOffsets?.frame2),
          transform: `rotate(${FRAME2.rotate}deg)`,
        }}
      >
        <img src={urlsToUse[1]} className="w-full h-full object-cover" />
      </div>
      <div
        className="absolute overflow-hidden bg-white z-[-10]"
        style={{
          ...getFrameSizeAbsolute(FRAME3, frameOffsets?.frame3),
          transform: `rotate(${FRAME3.rotate}deg)`,
        }}
      >
        <img src={urlsToUse[2]} className="w-full h-full object-cover" />
      </div>
      <img
        src={`${staticFileFolder}/header-cutout.png`}
        ref={imageRef}
        className="relative z-10"
        onLoad={(e) => {
          setBoundingBox((e.target as HTMLImageElement).getBoundingClientRect())
        }}
      />
    </>
  )
}
