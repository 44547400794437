import HeaderCatch from '../../../assets/photo-gifts/header_catch.svg?react'

export default function BannerSection() {
  return (
    <section className="flex flex-col items-center justify-center space-y-4 pt-6 pb-8">
      <HeaderCatch />
      <p className="text-gray-12 font-light text-center">
        Make family members smile with
        <br /> unforgettable personalized gifts
      </p>
    </section>
  )
}
