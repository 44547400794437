import { useCallback, useMemo, useEffect, useState } from 'react'
import { useApi } from '../../../hooks/useApi'
import { useRollbar } from '@rollbar/react'
import { useCuratedPhotosContext } from '../contexts/CuratedPhotosContext'
import type { CuratedPhotoForPhotoProducts } from '../../../clients/fagl-server/types'

const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')

export default function useCuratedPhotos({
  convertToBase64 = true,
  forceFallback = false,
}: {
  convertToBase64?: boolean
  forceFallback?: boolean
}) {
  const { api } = useApi()
  const rollbar = useRollbar()
  const {
    curatedPhotos,
    curatedPhotosForPhotoProducts,
    arePhotosLoaded,
    arePhotosForPhotoProductsLoaded,
    isUsingFallback,
    isUsingFallbackForPhotoProducts,
    isShowingUserPhotos,
    setCuratedPhotos,
    setCuratedPhotosForPhotoProducts,
    setArePhotosLoaded,
    setArePhotosForPhotoProductsLoaded,
    setIsUsingFallback,
    setIsUsingFallbackForPhotoProducts,
    setIsShowingUserPhotos,
  } = useCuratedPhotosContext()

  // Add isLoading state
  const [isLoading, setIsLoading] = useState(false)
  const [familyId, setFamilyId] = useState<number | null>(null)

  const padding = 0.2
  // Memoize the loading function
  const loadPhotos = useMemo(
    () => async () => {
      if (forceFallback) {
        return null
      }

      const photos = await api.getCuratedPhotosForPhotoProducts(familyId)
      if (photos.length === 0) {
        throw new Error('0 curated photos for photo products found')
      }

      if (!convertToBase64) {
        return photos
      }

      const base64 = await api.convertCdnPhotosToBase64(
        photos.map(({ url, mediumId }) => ({ url, uuid: mediumId }))
      )

      const processedPhotos = await Promise.all(
        photos.map(async (photo, index) => {
          const img = new Image()
          img.src = base64[index].base64
          // Wait for image to load before proceeding
          await new Promise((resolve, reject) => {
            img.onload = () => resolve(img)
            img.onerror = reject
          })

          if (!ctx) {
            throw new Error('Could not get canvas context')
          }
          const actualX = img.width * (photo.x - padding)
          const actualY = img.height * (photo.y - padding)
          const actualWidth = img.width * (photo.width + padding * 2)
          const actualHeight = img.height * (photo.height + padding * 2)

          canvas.width = actualWidth
          canvas.height = actualHeight

          ctx.drawImage(
            img,
            actualX,
            actualY,
            actualWidth,
            actualHeight,
            0,
            0,
            actualWidth,
            actualHeight
          )

          const dataUrl = canvas.toDataURL()
          return dataUrl
        })
      )

      return {
        photos,
        base64,
        processedPhotos,
      }
    },
    [api, convertToBase64, forceFallback, familyId] // Dependencies
  )

  // Update the useEffect to manage loading state
  useEffect(() => {
    let mounted = true

    const loadAndSetPhotos = async () => {
      if (forceFallback) {
        setIsUsingFallbackForPhotoProducts(true)
        setArePhotosForPhotoProductsLoaded(true)
        return
      }

      setIsLoading(true)
      try {
        const result = await loadPhotos()

        if (!mounted) {
          return
        }

        if (result) {
          if (convertToBase64 && 'photos' in result) {
            setCuratedPhotosForPhotoProducts(
              result.photos.map((photo, index) => ({
                ...photo,
                croppedUrl: result.processedPhotos[index],
                url: result.base64[index].url,
              }))
            )
            setIsShowingUserPhotos(true)
          } else {
            setCuratedPhotosForPhotoProducts(
              convertToBase64 ? [] : (result as CuratedPhotoForPhotoProducts[])
            )
          }
        }
      } catch (error) {
        setIsLoading(false)
        if (!mounted) {
          return
        }
        setIsUsingFallbackForPhotoProducts(true)
        // rollbar.error('Error loading curated photos', error as Error)
      } finally {
        if (mounted) {
          setArePhotosForPhotoProductsLoaded(true)
          setIsLoading(false)
        }
      }
    }

    loadAndSetPhotos()

    return () => {
      mounted = false
    }
  }, [loadPhotos, familyId])

  // Replace getCuratedPhotosForPhotoProducts with a trigger to reload
  const getCuratedPhotosForPhotoProducts = useCallback(() => {
    setArePhotosForPhotoProductsLoaded(false)
  }, [])

  const getCuratedPhotos = useCallback(
    async (landscapeOnly: boolean = false) => {
      if (forceFallback) {
        setIsUsingFallback(true)
        setArePhotosLoaded(true)
        return
      }

      try {
        const photos = await api.getFamilyAlbumCuratedPhotos(landscapeOnly)
        if (photos.length === 0) {
          throw new Error('0 curated photos found')
        }

        if (convertToBase64) {
          const base64 = await api.convertCdnPhotosToBase64(
            photos.map((p) => ({ url: p.url, uuid: p.uuid }))
          )

          setCuratedPhotos(
            photos.map((photo, index) => ({
              ...photo,
              url: base64[index].base64,
            }))
          )
        } else {
          setCuratedPhotos(photos)
        }
      } catch (error) {
        setIsUsingFallback(true)
        // rollbar.error('Error loading curated photos', error as Error)
      } finally {
        setArePhotosLoaded(true)
      }
    },
    [api]
  )

  return {
    curatedPhotos,
    curatedPhotosForPhotoProducts,
    arePhotosLoaded,
    arePhotosForPhotoProductsLoaded,
    isUsingFallback,
    isUsingFallbackForPhotoProducts,
    isShowingUserPhotos,
    getCuratedPhotosForPhotoProducts,
    getCuratedPhotos,
    isLoading,
    setFamilyId,
  }
}
