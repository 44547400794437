import { useNavigate } from 'react-router-dom'
import { ProductCatalog } from '../../clients/productCatalog'
import PhotoGiftProductCard from '../../shared/PhotoGiftProductCard'
import { useCallback } from 'react'
import StickyFooter from '../../shared/StickyFooter'
import Button from '../../shared/buttons/Button'
import { useApi } from '../../hooks/useApi'
import useFamilyAlbumUserAgent from '../../hooks/useFamilyAlbumUserAgent'
import ScrollToTop from '../../shared/ScrollToTop'

function getThumbUrlForCategory(categoryId: string) {
  return `/photo-products/categories/${categoryId}/thumb.png`
}

export default function CategoryPage({
  cartHeader,
  productCatalog,
  showResumeInstructions,
  isCartEmpty,
}: {
  cartHeader: (title: string) => React.ReactNode
  productCatalog: ProductCatalog
  showResumeInstructions: boolean
  isCartEmpty: boolean
}) {
  const { recordDidSelectPodCategory } = useApi().recordUserAction.photoProducts
  const navigate = useNavigate()
  const { appVersionSupportsWebViewBackAndCloseButtons } = useFamilyAlbumUserAgent()

  const onBackClick = useCallback(() => {
    navigate('/photo-products')
  }, [navigate])

  const onCategoryClick = useCallback((categoryId: string) => {
    const numberOfProductsInSelectedCategory = productCatalog[categoryId].products.length
    const skipPodSubCategory = numberOfProductsInSelectedCategory === 1
    recordDidSelectPodCategory({
      podCategory: categoryId,
      skippedPodSubCategory: skipPodSubCategory,
    })
    if (skipPodSubCategory) {
      navigate(`/photo-products/add/${productCatalog[categoryId].products[0].productId}`, {
        state: {
          source: 'categories',
        },
      })
    } else {
      navigate(`/photo-products/category/${categoryId}`, {
        state: {
          source: 'categories',
        },
      })
    }
  }, [])

  const categories = Object.keys(productCatalog)

  return (
    <>
      <ScrollToTop />
      {showResumeInstructions && (
        <p className="p-4 bg-fa-bg-accent-tertiary text-fa-text-primary">
          {isCartEmpty ? (
            <>Something went wrong. We are sorry for the inconvenience.</>
          ) : (
            <>
              Your cart has been saved. You can resume your order by clicking on the cart icon in
              the top right corner.
            </>
          )}
        </p>
      )}
      <div className="flex flex-col h-screen">
        {cartHeader('Select a Photo Gift')}
        <div className="px-4 grow pb-8">
          <ul className="grid grid-cols-2 gap-4">
            {categories.map((category) => (
              <li key={category}>
                <button className="w-full" onClick={() => onCategoryClick(category)}>
                  <PhotoGiftProductCard
                    key={category}
                    src={getThumbUrlForCategory(category)}
                    text={productCatalog[category].title}
                    bgColor={productCatalog[category].bgColor}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
        {appVersionSupportsWebViewBackAndCloseButtons || (
          <StickyFooter>
            <Button colorVariant="primary" className="w-full" onClick={onBackClick}>
              Back
            </Button>
          </StickyFooter>
        )}
      </div>
    </>
  )
}
