import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { DesignTemplate, Cell } from '../../../clients/fagl-server/types'
import { PhotoProductEditCompleteCallback } from '../../../clients/fagl-server/types/photoProducts'
import Template from '../select-template/Template'
import Button from '../../../shared/buttons/Button'
import FooterButtons from '../../../shared/FooterButtons'
import CropIcon from '../../../assets/crop.svg?react'
import ReplacePhotoIcon from '../../../assets/replace-photo.svg?react'
import { BottomPanel } from '../../../shared/BottomPanel'
import PhotoSelector from '../components/PhotoSelector'
import useCrop from '../hooks/useCrop'
import CropDialog from '../components/CropDialog'
import html2canvas from 'html2canvas-pro'
import DialogCloseButton from '../../../shared/DialogCloseButton'
import { useApi } from '../../../hooks/useApi'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import toast from 'react-hot-toast'
import { PhotoSource } from '../../../hooks/useRecordUserAction'
type SourceImage = {
  original: string
  cropped: string
}

type CellEditState = {
  cellDefinition: Cell
  photoUrl: string | null
  replacementPhotoUrl: string | null
}

type CellsEditMap = Record<string, CellEditState>

function createInitialCellEditState(cells: Cell[], sourceImages: SourceImage[]) {
  return cells.reduce((acc, cell, index) => {
    const image = sourceImages[index]
    acc[cell.id] = {
      cellDefinition: cell,
      photoUrl: image?.original || null,
      replacementPhotoUrl: image?.cropped || null,
    }
    return acc
  }, {} as CellsEditMap)
}

export default function EditTemplatePage({
  template,
  sourceImages,
  previous,
  next,
  templateWidth,
  templateHeight,
  isEditMode,
}: {
  sourceImages: SourceImage[]
  template: DesignTemplate
  previous: () => void
  next: PhotoProductEditCompleteCallback
  templateWidth: number
  templateHeight: number
  isEditMode: boolean
}) {
  if (!template) {
    const navigate = useNavigate()
    useEffect(() => {
      navigate(`/photo-products/categories?showResumeInstructions=true`, {
        replace: true,
      })
    }, [navigate])
    return null
  }

  const templateElementId = 'template'
  const { appVersionSupportsWebViewBackAndCloseButtons } = useFamilyAlbumUserAgent()
  const { recordDidSelectPhotos } = useApi().recordUserAction.photoProducts
  const [isProcessing, setIsProcessing] = useState(false)
  const [cellState, setCellState] = useState<CellsEditMap>(
    createInitialCellEditState(template.cells, sourceImages)
  )

  const [editedCellId, setEditedCellId] = useState<number | null>(null)

  const cellsList = Object.values(cellState)
  const editedCell = editedCellId ? cellState[editedCellId] : null
  const isSingleCell = template.cells.length === 1
  const hasPhotosSelected = Object.values(cellState).some((cell) => cell.photoUrl)
  if (hasPhotosSelected && !editedCell && cellState[template.cells[0].id].photoUrl) {
    setEditedCellId(template.cells[0].id)
  }
  const [isChangingPhoto, setIsChangingPhoto] = useState(false)
  const [isConfirmingCrop, setIsConfirmingCrop] = useState(false)
  const { initCrop, isCropping, setIsCropping, confirmCrop, urls } = useCrop({
    original: null,
    cropped: null,
  })

  const [isPristine, setIsPristine] = useState(true)

  const onCellClick = (cellId: number) => {
    setEditedCellId(cellId)
  }

  const onConfirmCrop = useCallback(
    (cropped: string) => {
      if (!editedCellId) {
        return
      }
      setIsConfirmingCrop(true)
      confirmCrop(cropped)
      setCellState((prev) => {
        return {
          ...prev,
          [editedCellId]: {
            ...prev[editedCellId],
            replacementPhotoUrl: cropped,
          },
        }
      })
      setIsPristine(false)
      setIsCropping(false)
      setIsChangingPhoto(false)
      setIsConfirmingCrop(false)
    },
    [confirmCrop, editedCellId, recordDidSelectPhotos, setCellState]
  )

  const onNewPhotosSelected = useCallback(
    (newPhotoDataurl: string[], source: PhotoSource) => {
      if (editedCell) {
        recordDidSelectPhotos({
          photosSelected: 1,
          photosRequired: template.cells.length,
          photoSource: source,
        })
        initCrop(newPhotoDataurl[0])
        setIsCropping(true)
        return
      }

      if (newPhotoDataurl.length < template.cells.length) {
        toast.error(`Please select ${template.cells.length} photos`)
        return
      }

      recordDidSelectPhotos({
        photosSelected: newPhotoDataurl.length,
        photosRequired: template.cells.length,
        photoSource: source,
      })
      const newCellState = createInitialCellEditState(
        template.cells,
        newPhotoDataurl.map((url) => ({
          original: url,
          cropped: url,
        }))
      )
      setIsPristine(false)
      setCellState(newCellState)
      setEditedCellId(template.cells[0].id)
    },
    [editedCell, initCrop, setIsCropping, template.cells]
  )

  const onPrevious = useCallback(() => {
    previous()
  }, [previous])

  const sourceImageDataUrls = Object.values(cellState).map((cell) => ({
    original: cell.photoUrl ?? '',
    cropped: cell.replacementPhotoUrl ?? '',
  }))

  const onNext = useCallback(async () => {
    setIsProcessing(true)

    // hide the overlay so it is not captured in the screenshot
    document.querySelectorAll('.template-cell-overlay').forEach((el) => {
      el.classList.add('hidden')
    })
    const canvas = await html2canvas(document.querySelector(`#${templateElementId}`)!, {
      allowTaint: true,
    })

    setIsProcessing(false)
    next({
      photoUrl: canvas.toDataURL(),
      sourceImageDataUrls,
      isPristine,
    })
  }, [next, setIsProcessing, sourceImageDataUrls, templateElementId, isEditMode, isPristine])

  useEffect(() => {
    setCellState(createInitialCellEditState(template.cells, sourceImages))
  }, [sourceImages])

  const areAllCellsFilledWithUrls = cellsList.every(
    (cell) => cell.replacementPhotoUrl || cell.photoUrl
  )

  const title = areAllCellsFilledWithUrls ? 'Edit your gift' : `Select ${cellsList.length} photos`

  const renderOnCell = useCallback(
    (props: {
      cell: Cell
      photoUrl: string | null
      style: React.CSSProperties
      index: number
      cellZIndex: number
    }) => {
      const { cell, photoUrl, style, index, cellZIndex } = props
      const showSelectedState = editedCellId === cell.id && !isSingleCell
      return photoUrl ? (
        <button
          className={cx('w-full h-full peer', {
            selected: showSelectedState, // do not show checkmark on single cell template selected cell
          })}
          style={{
            ...style,
            zIndex: cellZIndex + 2,
          }}
          onClick={() => onCellClick(cell.id)}
        />
      ) : (
        <div
          style={style}
          className={cx('w-full h-full peer bg-[#E5E5E5] flex items-center justify-center', {
            selected: editedCellId === cell.id,
          })}
        >
          <div className="bg-white w-12 h-12 rounded-full flex items-center justify-center text-gray-9 font-bold">
            {index}
          </div>
        </div>
      )
    },
    [editedCellId]
  )

  const photos = cellsList.map((cell) => cell.replacementPhotoUrl || cell.photoUrl)
  const isPreviewDisabled = !areAllCellsFilledWithUrls
  return (
    <div className="h-screen bg-gray-2 relative">
      <div className="p-4 space-y-4">
        <h2 className="text-lg font-bold py-4">{title}</h2>
        <div className="space-y-2">
          <div id="template">
            <Template
              id={templateElementId}
              templateWidth={templateWidth}
              templateHeight={templateHeight}
              designTemplate={template}
              photos={photos}
              renderOnCell={renderOnCell}
            />
          </div>
          {editedCell && (
            <div className="font-bold text-gray-12 pt-2">
              {isSingleCell ? 'Crop or change this photo' : 'Tap a photo to crop or change it'}
            </div>
          )}
        </div>
        {editedCell && (
          <div className="space-y-4">
            <div className="space-y-2">
              <Button
                onClick={() => {
                  if (editedCell.photoUrl) {
                    initCrop(editedCell.photoUrl)
                    setIsCropping(true)
                  }
                }}
                colorVariant="primary:invert"
                className="w-full flex items-center justify-center"
              >
                <CropIcon className="w-5 mr-1" /> Crop this photo
              </Button>
              <Button
                colorVariant="primary:invert"
                onClick={() => {
                  setIsChangingPhoto(true)
                }}
                className="w-full flex items-center justify-center"
              >
                <ReplacePhotoIcon className="w-5 mr-1 relative top-0.5" /> Change this photo
              </Button>
            </div>
          </div>
        )}
        {!areAllCellsFilledWithUrls && editedCellId === null && (
          <PhotoSelector
            numberOfPhotos={cellsList.length}
            verticalLayout
            familyAlbumButtonVariant="primary"
            onPhotoSelected={onNewPhotosSelected}
          />
        )}
        <CropDialog
          isConfirming={isConfirmingCrop}
          url={urls.original}
          aspect={
            editedCell?.cellDefinition.width && editedCell?.cellDefinition.height
              ? editedCell.cellDefinition.width / editedCell.cellDefinition.height
              : 1
          }
          isOpen={isCropping}
          confirm={onConfirmCrop}
          close={() => {
            setIsCropping(false)
          }}
        />
        <BottomPanel
          isOverlayOpen={isChangingPhoto}
          close={() => {
            setIsChangingPhoto(false)
          }}
        >
          <div className="bg-white px-4 pb-8 pt-4 rounded-t-lg w-screen">
            <div>
              <DialogCloseButton onClick={() => setIsChangingPhoto(false)} />
            </div>
            <h3 className="font-bold text-center mb-4">Select a photo</h3>
            <div className="grid grid-col-1 gap-2">
              <PhotoSelector
                verticalLayout
                familyAlbumButtonVariant="primary"
                onPhotoSelected={onNewPhotosSelected}
              />
            </div>
          </div>
        </BottomPanel>
      </div>
      <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
        <FooterButtons
          buttons={[
            appVersionSupportsWebViewBackAndCloseButtons ? undefined : (
              <Button disabled={isProcessing} onClick={onPrevious} colorVariant="primary:invert">
                Back
              </Button>
            ),
            <Button
              isLoading={isProcessing}
              disabled={isProcessing || isPreviewDisabled}
              onClick={onNext}
              colorVariant="primary"
            >
              Preview
            </Button>,
          ]}
        />
      </footer>
    </div>
  )
}
