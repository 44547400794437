import { CuratedPhoto, DesignTemplate } from '../../../clients/fagl-server/types'
import Template from './Template'
import Button from '../../../shared/buttons/Button'
import StickyFooter from '../../../shared/StickyFooter'
import LoadingScreen from '../../../shared/LoadingScreen'
import { useApi } from '../../../hooks/useApi'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import { useEffect } from 'react'
import TemplateCard from './TemplateCard'
import ScrollToTop from '../../../shared/ScrollToTop'

function orderDesignTemplateByCustomOrder(
  designTemplates: DesignTemplate[],
  orderedTemplateIds: number[]
) {
  return designTemplates.sort((a, b) => {
    return orderedTemplateIds.indexOf(a.id) - orderedTemplateIds.indexOf(b.id)
  })
}

export default function SelectTemplatePage({
  variantId,
  selectTemplate,
  curatedPhotos,
  arePhotosLoaded,
  designTemplates,
  templateWidth,
  templateHeight,
  previous,
  showPreview,
  cartHeader,
  orderedTemplateIds,
}: {
  variantId: number
  selectTemplate: (template: DesignTemplate, position: number) => void
  curatedPhotos: CuratedPhoto[]
  arePhotosLoaded: boolean
  designTemplates: DesignTemplate[]
  templateWidth: number
  templateHeight: number
  previous: () => void
  showPreview: boolean
  cartHeader: React.ReactNode
  orderedTemplateIds: number[]
}) {
  const { appVersionSupportsWebViewBackAndCloseButtons } = useFamilyAlbumUserAgent()
  const { recordDidViewSelectTemplatePage } = useApi().recordUserAction.photoProducts

  const orderedDesignTemplates = orderDesignTemplateByCustomOrder(
    designTemplates,
    orderedTemplateIds
  )

  useEffect(() => {
    recordDidViewSelectTemplatePage({
      isShowingUserPhotos: showPreview,
    })
  }, [])

  if (!arePhotosLoaded) {
    return <LoadingScreen />
  }

  return (
    <>
      <ScrollToTop />
      <div className="flex flex-col h-screen">
        {cartHeader}
        <ul className="p-4 grid grid-cols-2 gap-4 gap-y-6 grow">
          {orderedDesignTemplates
            .filter((dt) => dt.productVariantIds.includes(variantId))
            .map((dt, index) => {
              const templateContent = showPreview ? (
                <Template
                  templateWidth={templateWidth}
                  templateHeight={templateHeight}
                  designTemplate={dt}
                  photos={curatedPhotos.map((photo) => photo.url)}
                />
              ) : (
                <img
                  className="disable-long-press"
                  src={dt.thumbnailUrl}
                  alt={`${dt.name} template thumbnail`}
                />
              )

              return (
                <li key={dt.id} className="disable-long-press">
                  <button onClick={() => selectTemplate(dt, index)}>
                    <TemplateCard name={dt.name}>{templateContent}</TemplateCard>
                  </button>
                </li>
              )
            })}
        </ul>
        {appVersionSupportsWebViewBackAndCloseButtons || (
          <StickyFooter className="z-10">
            <Button onClick={previous} className="w-full" colorVariant="primary">
              Back
            </Button>
          </StickyFooter>
        )}
      </div>
    </>
  )
}
