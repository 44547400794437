import { useApi } from './useApi'
import { Buffer } from 'buffer'

const useGenericDataFromSession = <P>() => {
  const {
    session: { data },
  } = useApi()

  const encoded = data
  const decoded = encoded ? Buffer.from(encoded, 'base64').toString('utf-8') : null

  try {
    if (decoded) {
      return {
        encoded,
        decoded: JSON.parse(decodeURIComponent(decoded)) as P,
      }
    }

    return {
      encoded,
      decoded: null,
    }
  } catch (err) {
    return {
      encoded,
      decoded: null,
    }
  }
}

export default useGenericDataFromSession
