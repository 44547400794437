import { useCallback, useState } from 'react'
import Button from '../../../shared/buttons/Button'
import QuantitySelector from '../components/QuantitySelector'
import ConfirmationDialog from '../../../shared/ConfirmationDialog'
import { CartItem, Cart as CartType } from '../../../clients/fagl-server/types/photoProductsCart'
import CheckoutProgressBar from '../components/CheckoutProgressBar'
import StickyFooter from '../../../shared/StickyFooter'
import TrashIcon from '../../../shared/icons/TrashIscon'
import { useApi } from '../../../hooks/useApi'
import DeliveryBanner from '../components/DeliveryBanner'

function CartListItem({
  item,
  updateItemQuantity,
  maxQuantity,
  priceFormatter,
  deleteItem,
  editItem,
}: {
  item: CartItem
  updateItemQuantity: (itemId: number, quantity: number) => void
  maxQuantity: number
  priceFormatter: (price: number) => string
  deleteItem: (itemId: number, title: string) => void
  editItem: (itemId: number, providerProductId: number) => void
}) {
  const onQuantityChange = useCallback(
    (quantity: number) => {
      updateItemQuantity(item.id, quantity)
    },
    [item.id, updateItemQuantity]
  )

  const onDeleteClick = useCallback(() => {
    deleteItem(item.id, item.metadata.title)
  }, [item.id, item.metadata.title, deleteItem])

  const onEditClick = useCallback(() => {
    editItem(item.id, item.metadata.providerProductId)
  }, [item.id, editItem])

  return (
    <li key={item.metadata?.title} className="flex items-center space-x-4">
      <div className="flex-shrink-0 flex-grow-0">
        <img
          src={item.metadata.previewCdnUrl}
          alt={item.metadata.title}
          className="w-16 h-16 object-cover rounded"
        />
        <button
          className="text-fa-primary text-s font-bold text-center w-full"
          onClick={onEditClick}
        >
          Edit
        </button>
      </div>
      <div className="flex-1 space-y-2">
        <div className="flex items-center justify-between" onClick={onDeleteClick}>
          <h3 className="font-bold">{item.metadata.title}</h3>
          <button>
            <TrashIcon className="w-3 text-fa-primary" />
          </button>
        </div>
        <div className="flex items-centerm justify-between">
          <div className="space-x-2">
            <QuantitySelector
              quantity={item.quantity}
              setQuantity={onQuantityChange}
              maxQuantity={maxQuantity}
            />
          </div>
          {priceFormatter(item.salePrice / 100)}
        </div>
      </div>
    </li>
  )
}

export default function Cart({
  deleteCartItem,
  next,
  maxQuantity,
  updateItemQuantity,
  cart,
  navigateBackToSource,
  priceFormatter,
  editItem,
}: {
  deleteCartItem: (itemId: number) => void
  next: () => void
  maxQuantity: number
  updateItemQuantity: (itemId: number, quantity: number) => void
  cart: CartType | null
  navigateBackToSource: () => void
  priceFormatter: (price: number) => string
  editItem: (itemId: number, providerProductId: number) => void
}) {
  const { recordDidTapCartKeepShopping } = useApi().recordUserAction.photoProducts

  const onNextClick = useCallback(() => {
    next()
  }, [next])

  const onKeepShoppingClick = useCallback(() => {
    recordDidTapCartKeepShopping()
    navigateBackToSource()
  }, [navigateBackToSource, recordDidTapCartKeepShopping])

  const [itemToConfirmDelete, setItemToConfirmDelete] = useState<{
    id: number
    title: string
  } | null>(null)

  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] = useState(false)

  const cartItems = cart?.items || []

  const subtotal = priceFormatter(
    cartItems.reduce((acc, item) => acc + item.salePrice * item.quantity, 0) / 100
  )

  const onItemDeleteClick = useCallback((itemId: number, title: string) => {
    setItemToConfirmDelete({ id: itemId, title })
    setIsDeletionConfirmationOpen(true)
  }, [])

  const onItemDeleteConfirm = useCallback(() => {
    if (!itemToConfirmDelete) {
      return
    }
    deleteCartItem(itemToConfirmDelete.id)
    setIsDeletionConfirmationOpen(false)
  }, [deleteCartItem, itemToConfirmDelete])

  const onDeletionConfirmationClose = useCallback(() => {
    setItemToConfirmDelete(null)
  }, [])

  const onItemDeleteCancel = useCallback(() => {
    setIsDeletionConfirmationOpen(false)
  }, [])

  const onItemEditClick = useCallback(
    (itemId: number, providerProductId: number) => {
      editItem(itemId, providerProductId)
    },
    [editItem]
  )

  const items = cart?.items || []

  const cartContent =
    items.length > 0 ? (
      <>
        <div className="flex flex-col flex-grow overflow-auto">
          <div className="px-4 space-y-4 flex flex-col pb-4">
            <header>
              <h2 className="font-bold text-xl text-center">Shopping Cart</h2>
            </header>
            <section>
              <ul className="space-y-4">
                {cartItems.map((item) => (
                  <CartListItem
                    editItem={onItemEditClick}
                    deleteItem={onItemDeleteClick}
                    key={item.itemIdentifier}
                    item={item}
                    updateItemQuantity={updateItemQuantity}
                    maxQuantity={maxQuantity}
                    priceFormatter={priceFormatter}
                  />
                ))}
              </ul>
            </section>
          </div>
        </div>
        <div className="sticky bottom-0 border-t border-gray-5">
          <div className="px-4 py-3 space-y-2 bg-white">
            <div className="font-bold text-center">Subtotal: {subtotal}</div>
            <p className="text-fa-text-secondary text-s text-center">
              Shipping calculated at checkout
            </p>
          </div>
          <StickyFooter>
            <div className="grid grid-cols-2 gap-2">
              <Button onClick={onKeepShoppingClick} colorVariant="primary:invert">
                Keep shopping
              </Button>
              <Button onClick={onNextClick} colorVariant="primary">
                Payment
              </Button>
            </div>
          </StickyFooter>
        </div>
        <ConfirmationDialog
          isOpen={isDeletionConfirmationOpen}
          close={onItemDeleteCancel}
          confirm={onItemDeleteConfirm}
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          title={`Are you sure you want to remove ${itemToConfirmDelete?.title}?`}
          message="You will lose what you have uploaded and edited."
          afterLeave={onDeletionConfirmationClose}
        />
      </>
    ) : (
      <>
        <div className="flex-grow flex flex-col justify-center">
          <div className="py-8 space-y-4 text-center px-16">
            <h3 className="font-bold text-xl">Your cart is empty</h3>
            <p className="text-gray-11">
              Make unforgettable personalized gifts for you and your loved ones!
            </p>
          </div>
        </div>
        <StickyFooter>
          <Button className="w-full" onClick={navigateBackToSource} colorVariant="primary">
            Start shopping
          </Button>
        </StickyFooter>
      </>
    )

  return (
    <div className="min-h-screen bg-gray-2 flex flex-col">
      <div className="shrink-0 py-4">
        <CheckoutProgressBar
          onBackClick={undefined}
          orderedSteps={['Cart', 'Payment', 'Complete']}
          activeStepNumber={1}
        />
      </div>
      {cartContent}
    </div>
  )
}
